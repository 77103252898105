<template>
    <div class="court-fee-calculator">
      <h1>Калькулятор государственной пошлины</h1>
  
      <!-- Выбор типа суда -->
      <div class="form-group">
        <label for="courtType">Выберите тип суда:</label>
        <select v-model="courtType" @change="resetCalculator">
          <option value="arbitration">Расчет госпошлины в арбитражный суд</option>
          <option value="general">Расчет госпошлины в суд общей юрисдикции</option>
        </select>
      </div>
  
      <!-- Тип иска -->
      <div class="form-group">
        <label for="claimType">Выберите тип иска:</label>
        <select v-model="claimType">
          <option value="property">Имущественный</option>
          <option value="non-property">Неимущественный</option>
        </select>
      </div>
  
      <!-- Ввод цены иска -->
      <div class="form-group" v-if="claimType === 'property'">
        <label for="claimAmount">Цена иска (руб):</label>
        <input type="number" v-model="claimAmount" placeholder="Введите сумму" required />
      </div>
  
      <!-- Выбор категории истца -->
      <div class="form-group">
        <label for="plaintiffCategory">Категория истца:</label>
        <select v-model="plaintiffCategory">
          <option value="individual">Физическое лицо</option>
          <option value="organization">Юридическое лицо</option>
        </select>
      </div>
  
      <!-- Льготы -->
      <div class="checkbox-group">
        <label>
          <input type="checkbox" v-model="isExempted" />
          <span class="custom-checkbox"></span>
          Я освобожден от уплаты госпошлины
        </label>
      </div>
  
      <!-- Кнопка расчета -->
      <button type="button" @click="calculateFee">Рассчитать госпошлину</button>
  
      <!-- Результат расчета -->
      <div v-if="result !== null" class="result">
        <p><strong>Размер госпошлины:</strong> {{ result }} руб.</p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'CalculatorSecond',
    data() {
      return {
        courtType: 'arbitration',
        claimType: 'property',
        claimAmount: 0,
        plaintiffCategory: 'individual',
        isExempted: false,
        result: null
      };
    },
    methods: {
      resetCalculator() {
        this.claimType = 'property';
        this.claimAmount = 0;
        this.plaintiffCategory = 'individual';
        this.isExempted = false;
        this.result = null;
      },
      calculateFee() {
        let fee = 0;
  
        if (this.isExempted) {
          this.result = 0;
          return;
        }
  
        if (this.courtType === 'arbitration') {
          if (this.claimType === 'property') {
            fee = this.calculateArbitrationPropertyFee(this.claimAmount);
          } else {
            fee = 600; // Фиксированная сумма для неимущественных исков в арбитражном суде
          }
        } else {
          if (this.claimType === 'property') {
            fee = this.calculateGeneralPropertyFee(this.claimAmount);
          } else {
            fee = 200; // Фиксированная сумма для неимущественных исков в суде общей юрисдикции
          }
        }
  
        this.result = fee;
      },
      calculateArbitrationPropertyFee(amount) {
        // Пример расчета госпошлины для арбитражного суда
        if (amount <= 200000) {
          return amount * 0.01; // 1% для иска до 200 000
        } else if (amount <= 1000000) {
          return 2000 + (amount - 200000) * 0.005; // 2000 + 0.5% от суммы свыше 200 000
        } else {
          return 7000 + (amount - 1000000) * 0.0001; // 7000 + 0.01% от суммы свыше 1 000 000
        }
      },
      calculateGeneralPropertyFee(amount) {
        // Пример расчета госпошлины для суда общей юрисдикции
        if (amount <= 200000) {
          return amount * 0.04; // 4% для иска до 200 000
        } else if (amount <= 1000000) {
          return 8000 + (amount - 200000) * 0.02; // 8000 + 2% от суммы свыше 200 000
        } else {
          return 24000 + (amount - 1000000) * 0.0002; // 24000 + 0.02% от суммы свыше 1 000 000
        }
      }
    }
  };
  </script>
  
  <style scoped>
  .court-fee-calculator {
    margin: 0 auto;
    padding: 20px;
    background-color: #ffffff;
  }
  
  h1 {
    text-align: left;
    color: #31473A;
    font-size: 24px;
  }
  
  .form-group {
    margin-bottom: 15px;

  }
  .form-group input{
    width: 97.5%;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    color: #495057;
  }
  
  input,
  select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ced4da;
    font-size: 16px;
  }
  
  button {
    width: 100%;
    padding: 12px;
    background-color: #31473A;
    color: white;
    border: none;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  
  button:hover {
    background-color: #31473A;
  }
  
  .result {
    margin-top: 20px;
    padding: 15px;
    background-color: #dff0d8;
    color: #3c763d;
    text-align: center;
  }
  
.checkbox-group {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.checkbox-group label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.checkbox-group input[type="checkbox"] {
  margin-right: 10px;
  width: 20px;
  height: 20px;
  accent-color: #31473A;
}
  </style>
  