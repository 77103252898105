<template>
  <div>
    <HeaderComponent />
    <HeroComponent />
    <AdvantagesComponent />
    <ServicesComponent />
    <LawyersComponent />
    <ContactFormComponent />
    <FooterComponent />
    <!-- Здесь будет работать Cleversite -->
  </div>
</template>

<script>
import HeaderComponent from "@/components/HeaderComponent.vue";
import HeroComponent from "@/components/HeroComponent.vue";
import AdvantagesComponent from "@/components/AdvantagesComponent.vue";
import LawyersComponent from "@/components/LawyersComponent.vue";
import ServicesComponent from "@/components/ServicesComponent.vue";
import ContactFormComponent from "@/components/ContactFormComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";

export default {
  name: 'HomeView',
  components: {
    HeaderComponent,
    HeroComponent,
    AdvantagesComponent,
    ServicesComponent,
    LawyersComponent,
    ContactFormComponent,
    FooterComponent
  },
  mounted() {
    window.onload = () => {
      const script = document.createElement('script');
      script.src = '//widget.cleversite.ru/widget/128185/198949/';
      script.async = true;
      document.body.appendChild(script);
    }
  },
  metaInfo() {
    return {
      title: 'Московская коллегия адвокатов “Солидарность” - Профессиональные услуги адвокатов',
      meta: [
        { name: 'description', content: 'Официальный сайт Московской коллегии адвокатов “Солидарность”. Услуги адвокатов для физических и юридических лиц. Профессиональные консультации и защита интересов.' },
        { name: 'keywords', content: 'адвокаты, юридические услуги, защита, консультации, Москва, уголовные дела, семейные дела, адвокатская помощь' },
        { property: 'og:title', content: 'Московская коллегия адвокатов “Солидарность”' },
        { property: 'og:description', content: 'Получите квалифицированную юридическую помощь от опытных адвокатов Москвы. Мы защищаем ваши интересы и предоставляем профессиональные консультации.' },
        { property: 'og:image', content: '@/assets/heroImage.jpg' },  // Путь к изображению
        { property: 'og:url', content: window.location.href },  // URL текущей страницы
        { property: 'og:type', content: 'website' }
      ]
    }
  }
};
</script>
