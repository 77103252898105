<template>
  <div id="app">
    <router-view />
    
  </div>
</template>

<script>
export default {
  name: "App"
};
</script>

<style>
/* Глобальные стили */
body {
  margin: 0;
  font-family: 'Gill Sans';
}
</style>
